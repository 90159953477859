<template>
 <div>
   <b-row>
    <b-col md="1">
    </b-col>
    <b-col md="10">
      <b-card md="1" bg-variant="none"> {{description}}</b-card>
    </b-col>
    <b-col md="1">
    </b-col>
   </b-row>
   <br>
    <b-row>
      <b-col md="1">
      </b-col>
      <b-col md="10">
        <ams-card :json_ams="ams_data"> </ams-card>
      </b-col>
      <b-col md="1">
        <data-navagations
          @update="doUpdate"
          @cancel="doCancel"
          @help="doHelp"
        ></data-navagations>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import {belongsToDescription} from '@/utils'
import AmsCard from './AmsCard.vue'
import DataNavagations from './DataNavagations.vue'

export default {
  components: {
    AmsCard,
    DataNavagations
  },
  props: {
    id: {
      Type: String
    }
  },
  data () {
    return {
      ams_type: 'work-groups',
      ams_api_path: 'api/admin/v1/',
      ams_data: {},
      description: undefined,
      describe_belongs_to: [
        {
          prepend: '',
          type: 'brands',
          description_attr: 'name',
          append: ': '
        },
        {
          prepend: '',
          type: 'product-models',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: '#',
          type: 'product-listings',
          description_attr: 'edition',
          append: ''
        },
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ''
        }
      ]
    }
  },
  methods: {
    apiPath() {
      return this.ams_api_path + this.ams_type.replace(/-/g, '_') + '/' + this.id
    },
    doCancel() {
      this.$router.go(-1)
    },
    doUpdate() {
      this.$router.push( {path: '/' + this.ams_type + '/' + this.id + '/update'})
    },
    doHelp() {
    }
  },
  mounted () {
    http_v1.get(this.apiPath(), {
    }).then(response => {
      if( response.data) {
        this.ams_data = response.data.data
        this.description = belongsToDescription(
          response.data.data, response.data.included, this.describe_belongs_to
        )
        this.$emit('gotAttrs', this.ams_data?.attributes, this.description)
      }
    }).catch(error => {
      if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
  }
}
</script>