<template>
  <ams-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
    :describe_belongs_to="describe_belongs_to"
    :filtering="filtering"
    :refresh="refresh"
    :refreshed="doRefresh"
    :reroute_name="reroute_name"
    :ams_template="ams_template"
  />
</template>

<script>
import AmsTable from './AmsTable.vue'

export default {
  components: {
    AmsTable
  },
  props: {
    filtering: {
      Type: Object
    },
    refresh: {
      Type: Boolean
    },
    ams_template: {
      Type: Object
    }
  },
  data() {
    return {
      ams_type: 'actor-roles',
      ams_api_path: 'api/admin/v1',
      reroute_name: 'ActorRoleCreateScreen',
      ams_fields: [
        { 
          key: 'attributes.role',
          label: 'Role',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.can-create',
          label: 'Can Create',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.can-read',
          label: 'Can Read',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.can-update',
          label: 'Can Update',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.can-destroy',
          label: 'Can Destroy',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
        { key: 'actions', label: 'Actions' }
      ],
      describe_belongs_to: [
        {
          prepend: '',
          type: 'work-groups',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: ' ',
          type: 'profiles',
          description_attr: 'nickname',
          append: ' '
        },
        {
          prepend: ' of ',
          type: 'users',
          description_attr: 'email',
          append: ''
        }
      ]
    }
  },
  methods: {
    doRefresh() {
      this.emit('refreshed', true)
    }
  }
}
</script>