<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <work-group-show :id="id" @gotAttrs="assignAttrs"></work-group-show>
    <actor-role-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></actor-role-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import WorkGroupShow from './WorkGroupShow.vue'
import ActorRoleIndex from './ActorRoleIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    WorkGroupShow,
    ActorRoleIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        work_group_ids: this.id
      },
      ams_template: {
        type: 'actor-roles',
        description: undefined,
        attributes: {
          'work-group-id': this.id,
          'profile-id': undefined
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + ' ' + this.attrs['name']
    }
  }
}
</script>